.modalStyleWeb {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding-top: 8px;
  padding-left: 32px;
  padding-right: 8px;
  padding-bottom: 16px;
  border-radius: 10px;
  text-align: justify;
}

.modalStyleMobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  padding: 16px;
  border-radius: 10px;
  text-align: justify;
}

.itemDetailsModalTitle {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
}

.readMoreModalText {
  overflow-y: auto;
}

.readMoreModalTextMobile {
  overflow-y: auto;
}

.readMoreModalText::-webkit-scrollbar {
  width: 5px;
}

.readMoreModalText::-webkit-scrollbar-thumb {
  background: linear-gradient(91.95deg, #21bcd7 1.75%, #5ae5fe 98.13%);
  border-radius: 5px;
}

.readMoreModalTextMobile::-webkit-scrollbar {
  width: 3px;
}

.readMoreModalTextMobile::-webkit-scrollbar-track {
  background: #121212;
}

.readMoreModalTextMobile::-webkit-scrollbar-thumb {
  background: linear-gradient(91.95deg, #21bcd7 1.75%, #5ae5fe 98.13%);
  border-radius: 5px;
}
